<template lang="pug">
	.settings
		div(v-if='item')
			project-alerts
			h2.settings__title {{ $t('h1.settings') }}

			b-tabs(nav-class='tabs-filled tabs-filled-transparent' v-model='tab' no-fade @input="tabChange")
				b-tab(:title="$t('project.mainShort')")
					card-settings
				b-tab(v-if="!item.isNeuro()" :title="$t('project.decoration')")
					.d-flex.flex-column.flex-md-row
						.d-flex.flex-column.mb-3.mb-md-0.mr-md-3.card-basic.settings__card(:class="{disabled:projectInModeration}")
							form-add-project(@success='' @disabledSaveButton='($e) => disabledSaveButton = $e')
							.btn.btn-primary.w-100.mt-4(@click='saveProject()' :class="{'disabled': isPendingProject || disabledSaveButton || projectInModeration}") {{$t('forms.save')}}
						.d-flex.flex-column.settings__card(v-if='item.isActive() && me && me.hasFilledAccount() && me.hasFilledPaymentAccounts() && item.canSeeCover() && item.somePlansHasResourceActive()')
							payment-link.mb-3(:edit="false" :projectImage="item.image.contentUrl" :projectName="item.title")
							.mb-3.card-basic
								.d-flex.justify-content-between.align-items-center
									.d-flex.flex-column
										p.b1.mb-2 {{ $t('project.paymentPageStyle') }}
										span.b3.text-grey {{ pageColor ? $t('project.dark') : $t('project.light') }}
									switches#tool-mode-switch.set-theme-switch(v-model="pageColor")
							.disclaimer
								p.mb-0(v-html="$t('forms.coverDescription')")
				b-tab(v-if="!item.isNeuro()" :title="$t('project.analytics.postback')")
					webhooks.mb-4(v-if='item')
				//- b-tab(v-if="!item.isNeuro()" :title="$t('project.analytics.through')")
					//- integration.mb-4
					//- info(:title="$t('project.analytics.through')" :data="integrationLinkList")
				b-tab(v-if="!item.isNeuro()" :title="$t('project.analytics.moderation')")
					moderation.mb-4
		.loading(v-else)
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import CardSettings from '@/components/Cards/Settings';
import Webhooks from '@/components/Form/Webhooks';
import AddProject from '@/components/Form/AddProject';
import Integration from '@/components/Common/Integration';
import Moderation from '@/components/Common/Moderation';
import Info from '@/components/Cards/Info';
import { bus } from '@/main.js';
import Switches from 'vue-switches';
import PaymentLink from '@/components/Common/PaymentLink';
import ProjectAlerts from '@/components/Cards/ProjectAlerts';
import InfoIcon from '@/components/Svg/InfoIcon.vue';

export default {
    name: 'ProjectSettings',
    props: ['id'],
    components: {
        InfoIcon,
        CardSettings,
        Webhooks,
        Info,
        Integration,
        Moderation,
        'form-add-project': AddProject,
        Switches,
        ProjectAlerts,
        PaymentLink,
    },
    metaInfo() {
        return { title: this.getTitle };
    },
    data: () => ({
        tab: 0,
        disabledSaveButton: true,
        canBeDeleted: false,
        pageColor: false,
    }),
    created() {
        this.updateTabFromQuery();
        this.getShortList();

        if (this.item && this.item.id) this.getCanBeDeleted({ id: this.item.id });

        this.pageColor = this.item && this.item.isPaymentPageDark ? this.item.isPaymentPageDark : false;
    },
    computed: {
        ...mapGetters({
            item: 'project/opened',
            isPendingProject: 'project/isPending',
            isCanBeDeleted: 'project/canBeDeleted',
        }),
        getTitle() {
            if (this.item) {
                return this.item.title;
            }
            return 'Проект';
        },
        projectInModeration() {
            return (
                this.item?.moderationRequestLast &&
                ['NEW', 'BLOCKED'].includes(this.item?.moderationRequestLast?.status)
            );
        },
        webhooksLinkList() {
            return [
                { link: { name: 'tutorialPostBackParams' }, text: this.$t('tutorials.postBackParams'), blank: true },
                { link: { name: 'tutorialClickTypes' }, text: this.$t('tutorials.clickTypes'), blank: true },
                { link: { name: 'tutorialClickId' }, text: this.$t('tutorials.clickId'), blank: true },
            ];
        },
        integrationLinkList() {
            return [
                { link: { name: 'tutorialYandexMetrica' }, text: this.$t('tutorials.yandexMetrica'), blank: true },
                { link: { name: 'tutorialVKpixel' }, text: this.$t('tutorials.VKpixel'), blank: true },
                { link: { name: 'tutorialGoogleAnalytics' }, text: 'Google Analytics', blank: true },
                { link: { name: 'tutorialFacebook' }, text: 'Facebook', blank: true },
            ];
        },
    },
    methods: {
        ...mapActions({
            edit: 'project/edit',
            getShortList: 'project/shortList',
            getCanBeDeleted: 'project/canBeDeleted',
        }),
        async saveProject() {
            let r = { response: true };
            await bus.$emit('validateForm', r);

            if (r.response) {
                new Promise((res, rej) => {
                    bus.$emit('sendForm', { res, rej });
                })
                    .then(() => this.$notify('success', `😊&nbsp;&nbsp;&nbsp;${this.$t('success.projectEdit')}`))
                    .catch(v => this.$notify('error', `😢&nbsp;&nbsp;&nbsp;${v}`));
            }
        },
        updateTabFromQuery() {
            if (!this.$route.query?.tab) {
                return;
            }
            const tab = Number(this.$route.query.tab);
            if (tab !== this.tab) this.tab = tab;
        },
        tabChange(tab) {
            this.$router.push({ name: 'project_settings', query: { tab: tab } }).catch(() => {});
        },
    },
    watch: {
        pageColor(nv) {
            if (this.item && nv != this.item.isPaymentPageDark) {
                let canBeDeleted = this.isCanBeDeleted;

                this.edit({ id: this.item.id, isPaymentPageDark: nv }).then(v => {
                    this.item.isPaymentPageDark = v.isPaymentPageDark;
                    this.item.canBeDeleted = canBeDeleted;
                });
            }
        },
        $route() {
            this.updateTabFromQuery();
        },
        item() {
            this.pageColor = this.item ? this.item.isPaymentPageDark : null;
        },
        tab() {
            this.disabledSaveButton = true;
        },
    },
};
</script>

<style lang="scss">
@import '~@/assets/css/sass/_mixins.scss';

.settings {
    &__title {
        margin-bottom: 14px !important;

        @include respond-below(sm) {
            margin-bottom: 16px !important;
        }
    }

    &__card {
        width: 50%;

        &.disabled {
            opacity: 0.5;
            pointer-events: none;
        }
        @include respond-below(sm) {
            width: 100%;
        }
    }

    .nav-tabs {
        @include respond-below(md) {
            padding: 0 12px 20px;
            margin: 0 -12px;
        }
    }
}
.icon-info {
    color: #fcb64d;
    min-width: 20px;
    width: 20px;
    margin-right: 12px;
    & + .value {
        font-size: 12px;
    }
}
</style>
